import React from 'react'
import { createMuiTheme, ThemeProvider, responsiveFontSizes, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Box } from '@material-ui/core'
import useStyles from './style'
import moduleName from '../../../static/css/main.css'
import FloatingButton from '../FloatingButton'

const Wrapper = (props) => {
    const classes = useStyles()
    const { children, whatsappNumber } = props
    console.log("wa"+whatsappNumber)

    let theme = createMuiTheme({
        breakpoints:{
            values:{
                // xs:0,
                // sm:576,
                // md:768,
                // lg:992,
                // xl:1440
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            }
        },
        palette:{
            primary: {
                main: '#189A4A',
                light: '#F3FAF6',
              },
            secondary:{
                main:'#F2AC26'
            },
        },
        typography:{
            fontStyle:'normal',
            fontFamily:'Lato',
            h1:{
                fontWeight:"bold",
                fontSize:'3rem', // 48px
                fontFamily:'Lato-Bold'
            },
            h2:{
                fontWeight:"bold",
                fontSize:'2rem', // 32px
                fontFamily:'Lato-Bold'
            },
            overline:{
                fontWeight:700,
                fontSize:'0.875rem', // 14px
                letterSpacing:'2px'
            },
            subtitle1:{
                fontWeight:700,
                fontSize:'1.25rem', // 20px
                lineHeight:'1.5rem', // 24px
            },
            body1:{
                fontWeight:700,
                fontSize:'1rem', // 16px
            },
            body2:{
                fontWeight:400,
                fontSize:'1rem', // 16px
            },
        }
    })
    // if use responsiveFontSize cannot set lineHeight
    // theme = responsiveFontSizes(theme)

    return (
        <>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box bgcolor="#FFF">
                { children }
            </Box>
            <FloatingButton phoneNumber={whatsappNumber}/>
        </ThemeProvider>
        </>
    )
}

export default Wrapper
