import React from "react"
import { Fab, Box, Typography } from "@material-ui/core"

import WhatsAppIcon from "../../images/whatsapp-flat-icon.svg"

import useStyles from "./style"

const FloatingBtn = (props) => {
  const classes = useStyles()
  const { phoneNumber } = props
  const btnAction = () => {
    window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=Chat+with+cura`
  }
  return (
    <Box className={classes.root}>
      <Box mr={3} className={classes.actionText}>
        <Typography variant="body2">Chat with us now</Typography>
      </Box>
      <Fab color="primary" aria-label="add" className={classes.actionBtn} onClick={btnAction} >
        <img src={WhatsAppIcon} />
      </Fab>
    </Box>
  )
}

export default FloatingBtn
