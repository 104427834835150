import { makeStyles } from '@material-ui/core/styles'

const buttonPosition = "1.563rem"
const buttonSize = "4.25rem"
const useStyles = makeStyles( theme => ({
    root: {
        display:'flex',
        alignItems:'center',
      position:'fixed',
      bottom:buttonPosition,
      right:buttonPosition,
    //   width:buttonSize,
    //   height:buttonSize,
    //   background: "pink",
      
    },
    actionText:{
        color:"#C4C4C4",
        display:'none'
    },
    actionBtn:{
        background:theme.palette.primary.main,
        '&:hover':{
            actionText:{
                display:'block'
            }
        }
    },
  }))


export default useStyles